.container_sidebar{
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 220px;
    box-shadow: 8px 5px 7px #ccc;
    z-index: 9;
    background: #fff;
}

.container_sidebar.active{
    width: 60px;
}

.container_sidebar .image_logo_admin .img{
    width: 90%;
    height: 53px;
}

.container_sidebar .image_logo_admin .img.active{
    display: none;
}

.container_sidebar .image_logo_admin{
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ccc;
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 5px;
    font-size: 20px;
    font-weight: bold;
    height: 50px;
}

.container_sidebar .image_logo_admin span{
    cursor: pointer;
}

.container_lien_admin .titre_admin span{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 8px 15px #ccc;
    border-radius: 50%;
    
}

.container_lien_admin .titre_admin{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ccc;
    font-size: 20px;
}

.container_lien_admin .liens1{
    border-bottom: 1px solid #ccc;
}

.container_lien_admin .liens1 .btn_ouvert,
.container_lien_admin .liens1 .btn_liens div{
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px;
    width: 100%;
    font-size: 18px;
    cursor: pointer;
    transition: 0.4s;
    border-radius: 5px;
}

.container_lien_admin .liens1 .btn_ouvert{
    justify-content: space-between;
}

.btn_ouvert.active,
.btn_liens .btn_click.active{
    background: #ececec;
}

.container_lien_admin .liens1 .btn_ouvert label{
    cursor: pointer;
}

.container_lien_admin .liens1 .btn_ouvert:hover,
.container_lien_admin .liens1 .btn_liens div:hover{
    background: #ececec;
}

.container_lien_admin .liens1 .btn_ouvert span{
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;
}

.container_lien_admin .liens1 .btn_liens label{
    cursor: pointer;
}

.container_lien_admin .liens1 .btn_liens{
    margin-left: 15px;
}

.disparu{
    display: block;
}

.disparu.active{
    display: none;
}

/* navbar */

.container_navbar_admin{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    padding-left: 240px;
    padding-right: 20px;
    box-shadow: 0px 8px 10px #ccc;
}

@media only screen and (max-width: 770px){
    .container_sidebar{
        width: 60px;
    }
    .container_sidebar .image_logo_admin .img,
    .disparu,
    .div_fereme_sidebar{
        display: none;
    }

    .div_fereme_sidebar{
        position: fixed;
        width: 100%;
        height: 100vh;
        background: rgba(95, 95, 95, 0.3);
    }
    .container_sidebar.active{
        width: 220px;
    }
    .container_sidebar .image_logo_admin .img.active,
    .disparu.active,
    .div_fereme_sidebar.active{
        display: block;
    }

    .container_navbar_admin{
        padding-left: 80px;
    }
}

.inscription_inputs .cont_input input,
.inscription_inputs .cont_input select,
.inscription_inputs .cont_input textarea{
    width: 100%;
    height: 35px;
    min-width: 140px;
}

.body_modal_details {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 70%;
    min-width: 220px;
    padding: 20px;
    max-height: 90vh; 
    overflow-y: auto;
    z-index: 101;
}

.donne_affiche .div1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
    box-shadow: 3px 3px 5px #ccc;
}