.container_acceuil{
    background-image: linear-gradient(to bottom, rgba(0,0,0,0.6), rgba(0,0,0,0.5)), url(./fond_acceuil.jpeg);
    height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -30px;
    width: 100%;
    /* height: 100%; */
    /* margin-top: -40px; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.container_acceuil .petite_text{
    text-align: center;
}

.sous_contenu_tete_acceuil{
    margin-bottom: -90px;
    width: 50%;
}

.sous_contenu_tete_acceuil .centre{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.sous_contenu_tete_acceuil .bienvenu,
.sous_contenu_tete_acceuil .petite_text{
    font-size: 16px;
}

.sous_contenu_tete_acceuil .venture_mere{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sous_contenu_tete_acceuil .venture{
    border-bottom: 4px solid #FF3839;
    margin-bottom: 10px;
    width: 85%;
}

.sous_contenu_tete_acceuil .venture .prem{
    font-size: 4vw;
    font-weight: bold;
    margin-bottom: -20px;
}

.sous_contenu_tete_acceuil .venture .prem span{
    color: #FC071E;
}

.sous_contenu_tete_acceuil .venture .dex{
    font-size: 3.7vw;
    font-weight: bold;
}

.btn_contenu_acceuil{
    justify-content: space-around;
    gap: 50px;
    margin-top: 30px;
}

.commence_btn_contenu_acceuil{
    background: #FF3839;
    width: 180px;
    height: 50px;
    border-radius: 5px;
}

.recherche_contenu_acceuil input{
    outline: none;
    color: #202020;
    font-size: 17px;
    border: none;
    height: 50px;
    border-radius: 5px;
    width: 100%;
}

.recherche_contenu_acceuil{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 40%;
    min-width: 140px;
    height: 50px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.recherche_contenu_acceuil .icone_cherche{
    font-size: 22px;
    color: #727272;
    margin-left: 3px;
}

.commence_btn_contenu_acceuil{
    border: 1px solid #831a1a;
    color: #fff;
    transition: 0.4s;
}

.commence_btn_contenu_acceuil:hover{
    background: #ff8484;
}