.container_apropos p{
    text-align: justify;
}

.text1 p{
    text-align: justify;
}

.titre_abonement p{
    font-size: 20px;
}

.titre_abonement h1{
    font-size: 60px;
}

.block2_img,
.block3_img{
    display: flex;
    align-items: center;
    justify-content: center;
}

.block2 img{
    width: 20vw;
}

.image_apropos{
    display: flex;
    align-items: center;
    justify-content: center;
}

.div_abonement div{
    padding-top: 30px;
    padding-bottom: 30px;
}

.div_abonement{
    border-radius: 10px;
    transition: 0.4s;
}

.div_abonement:hover{
    background: #4E2D92;
    color: #fff;
    /* height: 200px; */
}

.div_abonement label{
    padding: 5px;
    background: #fff;
    color: #4E2D92;
    border-radius: 5px;
    cursor: pointer;
}

.table_apropos{
    width: 100%;
}

.table_apropos table{
    width: 100%;
}

.table_apropos tr{
    height: 50px;
}

.table_apropos td{
    width: 5%;
    /* border:  1px solid black; */
}

.table_apropos td img{
    float: right;
    margin-right: 6vw;
    /* align-items: r; */

}

.table_apropos .tr_color{
    background: #F9F2FF;
    border-radius: 5px;
}

.table_apropos .long_data{
    white-space: nowrap;
}

.block1_abonnement p{
    text-align: center;
}

@media only screen and (max-width:400px){
    .titre_abonement h1{
        font-size: 40px;
    }
}