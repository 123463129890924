.container_payment select,
.container_payment input{
    width: 100%;
    min-width: 200px;
    height: 35px;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgb(189, 189, 189);
    padding-left: 10px;
}

.titre_lieu_livraison .ajout_liv{
    float: right;
    color: #FF3839;
    cursor: pointer;
}

.input_radio_pay span span{
    display: flex;
    align-items: center;
    gap: 14px;
    /* justify-content: space-between; */
}

.input_radio_group #enligne,
.input_radio_group #livraison{
    width: 23px;
    min-width: 23px;
}