.tete_categorie{
    display: flex;
    align-items: center;
    justify-content: center;
}

.tete_categorie .cherche_categorie{
    /* float: right; */
    position: absolute;
    right: 7vw;
}

.cherche_categorie{
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border: 1px solid rgb(92, 92, 92);
    border-radius: 5px;
}

.cherche_categorie input{
    width: 100%;
    outline: none;
    border: none;
    border-right: 1px solid rgb(92, 92, 92);
    margin: 3px;
}

.cherche_categorie .icone_categorie{
    margin-right: 3px;
    font-size: 18px;
    color: 1px solid rgb(92, 92, 92)
}

.body_categorie{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.body_categorie img{
    width: 100%;
    height: 80%;
}

.body_categorie .sous_body_categorie{
    box-shadow: 0px 2px 7px 2px rgb(109, 109, 109);
    width: 200px;
    height: 150px;
}

.body_categorie .sous_body_categorie .image_categorie{
    width: 200px;
    height: auto;
}

.body_categorie .sous_body_categorie .nom_categorie{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
}

@media only screen and (max-width: 625px){
    .tete_categorie{
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .tete_categorie .cherche_categorie{
        position: relative;
        width: 150px;
    }
}