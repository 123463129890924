.container_detail .titre_tete{
    display: flex;
    align-items: center;
    justify-content: center;
}

.container_detail .titre_tete h3{
 font-size: 20px;
 font-weight: bold;
}

.container_detail .titre_tete

.container_detail .text_detail{
    display: flex;
    align-items: center;
    justify-content: center;
}

.container_detail .body_detail_produit .image_detail img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.container_detail .body_detail_produit .text_detail h3{
    font-size: 18px;
    font-weight: bold;
}

.contenu_desc_detail p{
    text-align: justify;
}

.text_detail button{
    color: #fff;
    background: #0A6900;
    border-radius: 3px;
    transition: 0.4s;
}

.text_detail button:hover{
    background: #40ac35;
}

.footer_detail .div{
    height: 150px;
    width: 100%;
    /* box-shadow: 1px 3px 5px rgb(143, 143, 143); */
}

.footer_detail div img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}