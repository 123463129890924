.container_footer{
    background: #0A6900;
    margin-top: 1px;
    padding-top: 40px;
    color: #fff;
}

.container_footer1 .logo{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width:992px){
    .container_footer1 .logo .logolong{
        margin-top: -90px;
    }
}

.container_footer1 .logo .logolong{
    width: 23vw;
    /* height: 80px; */
    margin-bottom: 10px;
}

.lien_logo{
    display: flex;
    align-items: center;
    /* justify-content: space-around; */
}

.lien_logo a{
    padding-top: 20px;
    margin-right: 10px;
}

.lien_logo span{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    /* margin: 25px; */
    background: #FF3839;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
}

.lien_logo span:hover{
    background: #ff8484;
}

.lien_logo span img{
    width: 20px;
    height: 20px;
}

.container_footer1 .service a,
.container_footer1 .ressource a{
    text-decoration: none;
    color: #fff;
    display: block;
    margin-top: 10px;
    font-size: 16px;
}

.container_footer1 .contact img{
    width: 20px;
    margin-right: 5px;
}

.container_footer1 .contact .mail,
.container_footer1 .contact .phone{
    display: flex;
    align-items: center;
    margin-bottom: 10px;

}

.container_footer1 .contact .lieu{
    display: flex;
}

.icone_lieu{
    margin-right: 15px;
}

.container_footer1 .contact{
    font-size: 16px;
}

.container_footer1 .service p,
.container_footer1 .ressource p,
.container_footer1 .contact p{
    font-size: 21px;
    font-weight: bold;
}

.container_footer2{
    font-size: 13px;
    color: #fff;
}

.container_footer2 .condition a{
    color: #fff;
    margin-left: 13px;
    transition: 0.4s;
}

.container_footer2 .condition a:hover{
    color: #2911ff;
    margin-left: 2vw;
}

.facebook_icon{
    color: #fff;
    font-size: 20px;
}

.sous_service,
.sous_ressource{
    margin-left: 60px;
}

.sous_ressource span{
    display: block;
    margin-bottom: 8px;
    cursor: pointer;
}

@media only screen and (max-width: 560px){
    .sous_ecran{
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media only screen and (max-width: 770px){
    .container_footer .service,
    .container_footer .ressource,
    .container_footer .contact .lieu,
    .container_footer .contact p{
        flex-direction: column;
        text-align: center;
        /* display: flex; */
        align-items: center;
        justify-content: center;
        margin-left: 0;
    }

    .container_footer1 .contact .mail,
    .container_footer1 .contact .phone{
        width: 100%;
    /* border: 1px solid #fff; */
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .sous_service,
    .sous_ressource{
        margin-left: 0px;
    }
}

@media only screen and (max-width:470px){
    #logolong{
        width: 170px;
    }
}

.message_erreur{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 19, 19, 0.5);
    width: 50%;
    height: 10%;
    border-radius: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
}