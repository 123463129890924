.tete_achat{
    margin-top: 15px;
    font-size: 18px;
}

.tete_achat,
.tete_achat .option_recherche,
.tete_achat .input_recherche1{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tete_achat .titre,
.label_secteur{
    display: flex;
    align-items: center;
    /* justify-content: left; */

}

.tete_achat .option_recherche{
    font-size: 18px;
}

.tete_achat .input_recherches {
    display: flex;
    align-items: center;
    justify-content: right;
}

.secteur_recherche{
    margin-bottom: 5px;
}

.secteur_recherche .option_secteur{
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 0.5px solid rgb(146, 146, 146);
    outline: none;
}

.tete_achat .input_recherche1 {
    height: 42px;
    background: #e9e9e9;
    border-radius: 5px;
    padding-right: 5px;
    width: 100%;
    min-width: 200px;
    justify-content: right;
}

.tete_achat .input_recherche1 input{
    border: none;
    outline: none;
    font-size: 18px;
    height: 42px;
    background: none;
    width: 100%;
    border-radius: 5px;
    padding-left: 10px;
}

.radio_rating{
    display: none;
}

.star{
    cursor: pointer;
}

/* shop */

.shop{
    /* margin: 3px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 3vw; */
}

.container_shop{
    border: 1px solid #aaaaaa;
    transition: 0.4s;
    border-radius: 5px;
    margin: 10px;
    width: 80%;
}

.container_shop:hover{
    box-shadow: 0px 2px 7px 5px #aaaaaa;
}

.container_shop img{
    width: 100%;
    min-height: 110px;
    max-height: 150px;
}

.info_shop{
    width: 100%;
    height: 100px;
    background: rgba(56, 56, 56, 0.6);
    position: relative;
    top: -30px;
    color: #fff;
    font-size: 25px;
    visibility: hidden;
    opacity: 0;
    transition: 0.4s;
}

.info_shop.visible{
    visibility: visible;
    top: -100px;
    opacity: 1;
}

.info_shop_icone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 8px;
}

.info_shop_icone .icone{
    cursor: pointer;
}

.detail_shop{
    margin-top: -100px;
}

.detail_shop h4{
    font-size: 18px;
    font-weight: bold;
}

.detail_shop p{
    font-size: 17px;
    margin-top: -8px;
    margin-bottom: -2px;
}

.detail_shop label{
    font-size: 17px;
    margin-top: -50px;
}

.erreur{
    padding: 5px;
    position: fixed;
    top: 20%;
    left: 50%;
    color: red;
    transform: translate(-50%, -50%);
    height: 40px;
    background: rgba(92, 92, 92, 0.5);
}

.photo_shop{
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.photo_shop img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 770px){
    #rating_id{
        display: block;
        visibility: visible;
        opacity: 1;
        top: -90px;
    }
}