.image_insciption{
    display: flex;
    align-items: center;
    z-index: -1;
}

.image_insciption img{
    margin-left: -200px;
    width: 150%;
}

.inscription_input input,
.inscription_input select{
    width: 100%;
    min-width: 230px;
    height: 40px;
    font-size: 18px;
    outline: none;
    padding-left: 8px;
}

.inscription_input p{
    position: absolute;
    margin-top: -3px;
    color: #FF3839;
    /* line-height: 23px; */
}

.cont_input{
    margin-bottom: 15px;
}

.btn_inscrire{
    width: 100%;
    height: 38px;
    border-radius: 5px;
    border: 1px solid rgb(133, 133, 133);
    background: #0A6900;
    color: #fff;
    font-size: 18px;
    font-weight: 900;
    transition: 0.4s;
    margin-top: 20px;
}

.btn_inscrire:hover{
    background: #5cca4f;
}

.lien_connecter{
    cursor: pointer;
    font-size: 18px;
    transition: 0.4s;
}

.lien_connecter:hover{
    color: #3483EF;
}

@media only screen and (max-width:992px){
    .image_insciption img{
        position: absolute;
        height: 150%;
        width: 50%;
    }
}

@media only screen and (max-width:770px){
    .image_insciption{
        display: none;
    }
}

@media only screen and (max-width:372px){
    .btn_inscrire{
        margin-top: 40px;
    }
}