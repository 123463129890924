.body_modal table{
    width: 100%;
}

.btn_detail_historique i{
    cursor: pointer;
    padding: 3px;
}

.sous_table,
.sous_table td,
.sous_table tr,
.sous_table th{
    border: 1px solid rgb(197, 197, 197);
}

.body_modal{
    z-index: 10;
}

.ferme_modal{
    z-index: 1;
}