.detail_produit table,
.detail_produit tr,
/* .detail_produit td, */
.detail_produit th{
    border: 1px solid rgb(161, 161, 161);
}

.detail_produit th{
    background: #CCCCCC;
}

.detail_produit .produit_image,
.detail_produit .produit_quantite{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
}

.detail_produit .produit_image{
    min-width: 100px;
    font-size: 16px;
}

.detail_produit .produit_image img{
    min-width: 100px;
    max-width: 120px;
}

.detail_produit .produit_quantite span{
    padding: 1px 3px;
    margin: 2px;
    background: #8a8a8a;
    cursor: pointer;
    color: #fff;
    border-radius: 3px;
}

.detail_produit .produit_quantite .qte{
    min-width: 48px;
    padding: 1px;
}

.detail_produit .select_provenance select{
    border: none;
    outline: none;
    width: 100%;
    padding: 7px 2px;
}

.detail_produit tr{
    box-shadow: 1px 2px 2px #CCCCCC;
}

.detail_produit table{
    width: 100%;
}

.produi_supprime i{
    color: #FF3839;
    padding: 3px;
    cursor: pointer;
}

.container_commande{
    border: 1px solid #8a8a8a;
}

.container_commande .tete_commande{
    background: #CCCCCC;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.container_commande .body_commande {
    padding: 7px 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container_commande .body_commande select{
    width: 100%;
    border: none;
    outline: none;
}

.container_commande .btn_commande{
    background: #0A6900;
    color: #fff;
    padding: 8px 2px;
    cursor: pointer;
    transition: 0.4s;
    font-weight: bold;
}

.container_commande .btn_commande:hover{
    background: #49c03c;
}

@media only screen and (max-width: 770px){
    .container_commande{
        width: 100%;
        margin-top: 20px;
    }
    .container_commande .body_commande {
        padding: 7px 1px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
}

.monter_zindex{
    z-index: 100;
}