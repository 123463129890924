.container_pub{
    position: absolute;
    top: 80px;
    color: rgb(255, 255, 255);
    width: 100%;
    background: rgba(3, 3, 3, 0.4);
    display: flex;
    align-items: center;
    /* justify-content: center; */
    overflow: hidden;
}

.container_pub1,
.container_pub2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100px;
}

.container_pub1{
    width: 50%;
    justify-content: left;
}

.container_pub1 .info_pub{
    width: 50%;
    text-align: center;
}

.container_pub1 .info_pub .titre{
    font-weight: bold;
    font-size: 18px;
}

.container_pub1 .info_pub div{
    min-width: 50%;
    margin-bottom: -20px;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.container_pub1 .info_pub div::-webkit-scrollbar {
    display: none;
}

.container_pub2 .info_pub div::-webkit-scrollbar {
    display: none;
}
 
.container_pub2{
    min-width: 50%;
}

.container_pub2 .desc_pub {
    min-width: 50%;
    height: 80px;
    /* border: 1px solid rgba(206, 206, 206, 0.4); */
    overflow-y: auto;
    padding: 5px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    
}

.container_pub2 .desc_pub p{
    text-align: justify;
}

.container_pub2 .desc_pub::-webkit-scrollbar {
    display: none;
}

@keyframes scrollContentY {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}

@keyframes scrollContentX {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.text_pub{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
}

.text_pub span{
    font-size: 25px;
    font-weight: bold;
    color: #FF3839;
    border: 1px solid #c0c0c0;
    padding: 10px;
}

.container_pub2 .img_pub{
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container_pub2 .img_pub img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media only screen and (max-width: 950px){
    .sous_container_pub{
        flex-direction: column;
        align-items: flex-start;
        background: transparent;
        justify-content: center;
        margin-left: 20px;
    }

    .sous_container_pub .container_pub1 {
        width: 100%;
        align-items: flex-end;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        background: rgba(3, 3, 3, 0.4);
    }

    .sous_container_pub .container_pub2 {
        /* display: inline-block; */
        margin-top: 10px;
        height: 50%;
        background: rgba(3, 3, 3, 0.4);
    }

    .sous_container_pub .container_pub2 img{
        height: 50px;
        width: 100%;
    }

    .text_pub span{
        font-size: 25px;
        font-weight: bold;
        padding: 3px;
    }

    .container_pub{
        position: absolute;
        top: 80px;
        color: rgb(255, 255, 255);
        width: 100%;
        background: transparent;
    }

    .info_pub .titre{
        margin-bottom: -2px;
    }

    .img_pub{
        height: 50px;
        border: 1px solid black;
    }
}