.container_profil{
    background: #f5f5f5;
}

.tete_container_profil .titre_profil{
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid black; */
}

.tete_container_profil .titre_profil h3{
    font-size: 25px;
    font-weight: bold;
}

.information_photo_profil{
    margin: 20px;
    background: #fff;
    border-radius: 5px;
}

.information_photo_profil .photo_profil{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 3px 7px rgb(163, 163, 163);
    /* padding: 10px; */
}

.information_photo_profil .photo_profil span{
    font-size: 25px;
    margin-bottom: -55px;
    margin-right: -75px;
    color: rgb(122, 122, 122);
    cursor: pointer;
    transition: 0.4s;
}

.information_photo_profil .photo_profil span:hover{
    color: rgb(182, 182, 182);
}

.information_photo_profil .photo_profil span input{
    display: none;
}

.lien_img_profil{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.lien_img_profil img{
    width: 30px;
    cursor: pointer;
}

/* Information du profil */

.information_profil{
    margin: 20px;
    margin-left: -15px;
    padding: 15px;
    background: #fff;
    border-radius: 5px;
}

.information_profil div{
    width: 100%;
    border-bottom: 1px solid rgb(207, 207, 207);
    padding-bottom: 3px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container_nav_profil{
    margin-top: 10px;
    margin-bottom: -10px;
    margin-right: 5px;
}

.tete_profil .container_nav_profil label{
    /* width: 100%; */
    padding: 5px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.btn_profil{
    cursor: pointer;
}

.btn_profil.active{
    background: #fff;
}

.btn_profil label,
.btn_historique label{
    cursor: pointer;
}

.btn_historique{
    cursor: pointer;
} 

.btn_historique.active{
    background: #fff;
}

.tete_info_profil{
    box-shadow: 1px 3px 7px rgb(182, 182, 182);
    padding: 10px;
}

.tete_info_profil .titre_info{
    font-size: 18px;
    font-weight: bold;
}

.tete_info_profil button{
    font-size: 18px;
    color: #fff;
    background: #0A6900;
    border-radius: 5px;
    border: 1px solid rgb(177, 177, 177);
    transition: 0.4s;
    gap: 3px;
}

.tete_info_profil button:hover{
    background: #43b935;
}

.nav_profil_minu{
    display: none;
}

@media only screen and (max-width:991px) and (min-width:768px){
    .nav_profil .container_nav_profil{
        display: flex;
    }

    .nav_profil .btn_profil,
    .nav_profil .btn_historique{
        width: 50%;
    }

    .nav_profil .btn_profil.active,
    .nav_profil .btn_historique.active{
        background: #fff;
    }
}

.container_modal .body_modal,
.model_detail_historique .body_modal{
    width: 70%;
    background: #fff;
    padding: 20px;
    /* border: 1px solid black; */
    box-shadow: 1px 3px 7px rgb(116, 116, 116);
    border-radius: 5px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (max-width:768px){
    .nav_profil_mini .nav_profil{
        display: none;
    }

    .nav_profil_minu{
        display: block;
    }

    .nav_profil_minu .container_nav_profil{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px;
        margin-bottom: -15px;
    }

    .nav_profil_minu .container_nav_profil div{
        width: 100%;
        margin: 0;
        padding: 8px;
    }

    .container_modal .body_modal,
    .model_detail_historique .body_modal{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

/* fenetre modale */

.ferme_modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
}

.input_modifie input,
.input_modifie select{
    width: 100%;
    min-width: 230px;
    height: 40px;
    font-size: 18px;
    outline: none;
    padding-left: 8px;
}

.input_modifie p{
    position: absolute;
    margin-top: -3px;
    color: #FF3839;
}

.btn_modifie{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.btn_modifie button{
    width: 120px;
    border: 1px solid rgb(114, 114, 114);
    border-radius: 5px;
    padding: 3px;
    transition: 0.4s;
}

.btn_modifie .btn_ferme_modif{
    background: rgb(202, 202, 202);
}

.btn_modifie .btn_ferme_modif:hover{
    background: rgb(236, 236, 236);
}

.btn_modifie .btn_modif{
    background: #0A6900;
    color: #fff;
}

.btn_modifie .btn_modif:hover{
    background: #43b935;
}