.container_navbar{
    background: #0A6900;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    font-size: 18px;
    transition: 0.4s;
    z-index: 10;
}

#container_navba{
}

.container_navbar .navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.container_navbar .navbar .logo_navbar{
    height: 60px;
    margin-top: -8px;
}

.container_navbar .navbar .lieu_profil{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container_navbar .navbar .container_lien_nav,
.container_lien_nav .lien_nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.container_lien_nav .lien_nav{
    /* visibility: hidden; */
}

.container_lien_nav .lien_nav.active{
    visibility: visible;
}

v{
    align-items: center;
}

.icone_panier{
    padding: 8px 1px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
}

.lien_span{
    color: #fff;
    text-decoration: none;
}

.lien_span_sous{
    color: #000000;
    text-decoration: none;
}

.container_lien_nav .lien_nav span::after{
    width: 0px;
    content: '';
    background: #FF3839;
    height: 3px;
    display: block;
    transition: all 0.4s ease;
}

.container_lien_nav .lien_nav span:hover::after{
    width: 100%;
}

.icone_panier:hover{
    background: #0e8a00;
}

.icone_panier span{
    padding: 1px;
    background: #FF3839;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 12px;
    width: 13px;
    height: 14px;
    position: relative;
    top: -8px;
    right: 3px;
}

.lien_nav span{
    cursor: pointer;
}

.icone_profil{
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    padding: 3px;
    border-radius: 3px;
    transition: 0.4s;
}

.icone_profil:hover{
    background: #0e8a00;
}

.navbar .icone{
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 0px;
}

@media only screen and (min-width: 1024px){
    .icone_ferme_navbar,
    .icone_ouvert_navbar{
        display: none;
    }
}

@media only screen and (max-width: 1024px){
    .icone_ferme_navbar{
        visibility: hidden;
    }
    .icone_ferme_navbar.active{
        visibility: visible;
    }

    .icone_ouvert_navbar{
        visibility: visible;
    }

    .icone_ouvert_navbar.active{
        visibility: hidden;
    }

    .container_navbar .container_lien_nav{
        flex-direction: column;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* display: none; */
        visibility: hidden;
        opacity: 0;
        transition: 0.4s;
    }

    .container_navbar.active{
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        /* flex-direction: column; */
    }

    .container_navbar .container_lien_nav.active{
        visibility: visible;
        opacity: 1;
        top: 1700%;
    }

    .container_navbar .lien_nav{
        flex-direction: column;
    }

    .container_navbar .navbar .logo_navbar{
        position: absolute;
        top: 10px;
        left: 0;
    }

    .sous_navbar{
        display: none;
        visibility: hidden;
    }

    #lien_nav{
        visibility: visible;
    }
}

.sous_navbar{
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    gap: 6vw;
    background: rgba(71, 71, 71, 0.7);
    z-index:1;
}

.sous_navbar .lien_span_sous1{
    color: #fff;
    text-decoration: none;
}

.sous_navbar span{
    cursor: pointer;
    font-size: 16px;
}

.sous_navbar span::after{
    content: '';
    display: block;
    width: 0%;
    height: 3px;
    background: #FF3839;
    transition: 0.4s;
}

.sous_navbar span:hover::after{
    width: 100%;
}

.con{
    height: 200vh;
}