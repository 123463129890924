.container_body{
    padding-left: 230px;
}

.container_body.active{
    padding-left: 80px;
}

.container_body .tete_body{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 8px 5px 7px #ccc;
}

.container_body .tete_body button{
    width: 100px;
    border: 1px solid black;
    border-radius: 5px;
    transition: 0.4s;
}

.container_body .tete_body div{
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 10px;
}

.container_body .tete_body input{
    width: 50%;
    min-width: 170px;
    outline: none;
    padding-left: 5px;
    border-radius: 5px;
    border: 1px solid rgb(128, 128, 128);
}

.container_body .tete_body button:hover{
    background: #ccc;
}

/* Modal detail */
.modal_detail{
    z-index: 2;
}
.modal_detail,
.modal_detail .ferme_modal{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
}

.modal_detail .ferme_modal{
    background-color: rgba(97, 97, 97, 0.3);
}

.modal_detail .body_modal_detail{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 60%;
    min-width: 250px;
    padding: 20px;
    z-index: 10;
}

.modal_detail .body_modal_detail .donne_affiche .div1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 8px 5px 7px #ccc;
    margin-bottom: 10px;
    padding: 5px;
}

.modal_detail .body_modal_detail .btn_detail{
    width: 100%;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal_detail .body_modal_detail .btn_double{
    width: 100%;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.modal_detail .body_modal_detail span button{
    width: 100px;
    border-radius: 5px;
    border: 1px solid rgb(107, 107, 107);
    background: rgb(196, 196, 196);
    padding: 5px;
    transition: 0.4s;
}

.modal_detail .body_modal_detail span button:hover{
    background: rgb(134, 134, 134);
}


@media only screen and (max-width: 770px){
    .container_body{
        padding-left: 70px;
    }
}

@media only screen and (max-width: 850px){
    .container_body .tete_body h4{
        display: block;
    }

    .container_body .tete_body div{
        display: block;
    }

    .container_body .tete_body div input,
    .container_body .tete_body div button{
        display: block;
        width: 100%;
        margin-bottom: 5px;
    }
}

.message_alert{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    background: rgba(112, 112, 112, 0.3);
    color: green;
    z-index: 10;
}

/* input  */

.container_input .cont_input {
    width: 100%;
    margin-bottom: 20px;
}

.container_input .cont_input input,
.container_input .cont_input textarea{
    width: 100%;
    height: 30px;
    outline: none;
    padding-left: 5px;
    border: 1px solid rgb(109, 109, 109);
    border-radius: 2px;
    min-width: 150px;

}

.container_input .cont_input textarea{
    min-height: 70px;
}

.container_input .cont_input .error_message{
    position: absolute;
    color: red;
}