.sous_titre_contact{
    display: flex;
    align-items: center;
    gap: 3px;
    margin-top: 20px;
}

.sous_titre_contact .ligne{
    border: 1px solid #D8D8D8;
    width: 130px;
}

.sous_titre_contact .text{
    font-size: 16px;
    font-weight: bold;
    color: #FF3839;
}

.grand_titre_contact {
    font-size: 45px;
    font-weight: bold;
}

.grand_titre_contact .span2{
    margin-top: -15px;
}

.grand_titre_contact .span_rouge{
    color: #FF3839;
}

.text_contact p{
    display: flex;
    align-items: center;
    text-align: justify;
}

.input_contact input,
.input_contact textarea{
    margin-bottom: 25px;
    outline: none;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #CACACA;
    font-size: 16px;
}

.input_contact input{
    height: 45px;
    padding-left: 10px;
}

.input_contact textarea{
    min-height: 80px;
}

.input_contact p{
    color: #FF3839;
    margin-top: -30px;
    position: absolute;
}

.contact_button{
    border: 1px solid #D8D8D8;
    background: #FF3839;
    color: #fff;
    padding: 7px 18px;
    border-radius: 5px;
    transition: 0.4s;
    margin-top: 10px;
}

.contact_button:hover{
    background: #ff7c7c;
}

.container2_contact{
    display: flex;
    align-items: center;
    justify-content: center;
}

.container2_contact img{
    width: 70%;
}

.block3_img img,
.block2_img img{
    width: 80%;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .grand_titre_contact {
        font-size: 33px;
    }
}

@media only screen and (max-width: 470px){
    .grand_titre_contact {
        font-size: 26px;
    }
}