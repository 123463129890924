.container_dash .div div{
    height: 100px;
    border: 1px solid rgb(187, 187, 187);
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 25px;
}

.container_dash .div div i{
    font-size: 35px;
}