.tete_facture{
    display: flex;
    justify-content: space-between;
}

.tete_facture img{
    width: 150px;
}

.identite {
    display: flex;
    justify-content: space-between;
}

@media print {
    .body_modal_detail {
        width: 100%;
    }

    .body_modal_detail .btn_double {
        display: none;
    }
}